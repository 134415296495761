<template>
    <div>
        <v-card flat>
            <v-card-text>
                <v-alert text prominent color="info">
                    <div class="d-flex align-center">
                        <div class="subtitle-2 mr-3 text-uppercase font-weight-bold">invite to:</div>
                        <v-select
                            :loading="fetchingInstances"
                            :disabled="fetchingInstances"
                            :items="instanceNames"
                            label="Target instance"
                            v-model="selectedInstance"
                            hide-details
                            outlined
                            dense></v-select>
                        <v-spacer></v-spacer>
                        <add-instance-dialog @inviteUsers="setInstanceNameToTheNewInstance($event.instanceName)">
                            <v-btn color="primary">
                                <v-icon small>add</v-icon>
                                <span class="font-weight-bold caption">Add New Instance</span>
                            </v-btn>
                        </add-instance-dialog>
                    </div>
                </v-alert>
                <div v-if="selectedInstance">
                    <v-radio-group :disabled="numInvitationsToSend !== 0" v-model="invitationType">
                        <div v-for="mode in invitationTypes" :key="mode.type">
                            <v-radio class="mt-3 caption" color="primary" :value="mode.value" :label="mode.type"></v-radio>
                            <span :class="[invitationType === mode.value ? 'caption secondary--text' : 'caption']">{{ mode.description }}</span>
                        </div>
                    </v-radio-group>
                    <v-form v-if="invitationType" ref="form" v-model="valid">
                        <v-textarea
                            v-model="emailsInput"
                            required
                            @input="updateEmailString"
                            rows="3"
                            auto-grow
                            :disabled="numInvitationsToSend !== 0"
                            outlined>
                            <template v-slot:label>Email addresses</template>
                        </v-textarea>
                    </v-form>
                    <div v-if="emailsInput.length" class="mt-3">
                        <h3 class="secondary--text">
                            The following users will receive an invitation email to become
                            <span v-if="invitationType === 'instanceEditors'">editors</span>
                            <span v-if="invitationType === 'instanceViewers'">viewers</span>
                            of "{{ selectedInstance }}"
                        </h3>
                        <div>
                            <ul v-for="(email, index) in emails" :key="index" class="mt-3">
                                <li
                                    v-if="
                                        email.status === emailValidationOutcomes.LOW_RISK_EMAIL || email.status === emailValidationOutcomes.UNKNOWN_RISK_EMAIL
                                    ">
                                    {{ email.email }}
                                    <v-chip class="ml-1" x-small color="success">
                                        <v-icon small class="mr-1">check_circle</v-icon>
                                        <span class="text-uppercase font-weight-bold">validated</span>
                                    </v-chip>
                                </li>
                                <li v-else-if="email.status === emailValidationOutcomes.HIGH_RISK_EMAIL">
                                    {{ email.email }}
                                    <v-chip class="ml-1" x-small color="error">
                                        <v-icon small class="mr-1">cancel</v-icon>
                                        <span class="text-uppercase font-weight-bold">validation failed</span>
                                    </v-chip>
                                </li>
                                <li v-else-if="email.status === emailValidationOutcomes.UNVALIDATED_EMAIL">
                                    {{ email.email }}
                                    <v-chip class="ml-1" x-small color="secondary">
                                        <v-icon small class="mr-1">warning</v-icon>
                                        <span class="text-uppercase font-weight-bold">validation error</span>
                                    </v-chip>
                                </li>
                                <li v-else-if="validatingEmails">
                                    {{ email.email }}
                                    <v-progress-circular :size="20" class="ml-1" indeterminate color="primary" />
                                </li>
                                <li v-else>{{ email.email }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <InvitationErrorsDialog
                    :showDialog="showInvitationErrorsDialog"
                    :errorType="validationErrorType"
                    :invalidEmails="invalidEmailsList"
                    @proceedWithTheInvitation="inviteUsers($event.value)"
                    @validateEmailsAgain="validateEmailsAndInviteUsers($event.value)" />
            </v-card-text>
            <v-card-actions>
                <div class="d-flex align-center justify-center flex-column w-100">
                    <v-checkbox
                        v-if="$store.state.userInfo.is_sysadmin"
                        v-model="generateInvitationLinksOnly"
                        class="mb-1 mt-5 pa-0"
                        label="Generate invitations but don't send emails"></v-checkbox>
                    <v-btn
                        :loading="numInvitationsToSend > 0 || inviting || validatingEmails"
                        class="text-md-center mr-1"
                        @click="validateEmailsAndInviteUsers(true)"
                        :disabled="!valid || !emailsInput || numInvitationsToSend > 0 || validatingEmails"
                        color="primary">
                        <v-icon small>add</v-icon>
                        Invite
                    </v-btn>
                    <v-alert v-if="error" color="error" icon="warning" class="mt-4 w-100" text>
                        <div class="d-flex flex-column">
                            <span class="font-weight-bold">Invitation Failure Error</span>
                            Failed to invite the following users
                            <ul>
                                <li v-for="(email, index) in failedInvitations" :key="index">
                                    {{ email }}
                                </li>
                            </ul>
                        </div>
                    </v-alert>
                </div>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import { splitEmails, sortArray } from '@/utils'
import { emailValidation } from '@/mixins/validateEmail'
import { mapState, mapGetters } from 'vuex'
import { enumsData } from '@/mixins/enums'
const AddInstanceDialog = () => import('@/modules/space/components/TheSpaceAddInstanceDialog')
const InvitationErrorsDialog = () => import('@/components/InvitationErrorsDialog')

export default {
    props: {
        dataForInstanceToInviteTo: { type: Object, default: null }
    },
    components: { AddInstanceDialog, InvitationErrorsDialog },
    mixins: [emailValidation, enumsData],
    data() {
        return {
            error: false,
            emailsInput: '',
            invitationType: 'instanceEditors',
            errorContent: 'An error has occurred',
            emailsData: [],
            emailList: [],
            failedInvitations: [],
            inviting: false,
            valid: false,
            numInvitationsToSend: 0,
            instanceMenu: false,
            selectedInstance: '',
            instanceSearch: '',
            tabValues: {
                INSTANCE_EDITOR_TAB: 'instanceEditors',
                INSTANCE_VIEWER_TAB: 'instanceViewers'
            },
            generateInvitationLinksOnly: false,
            invitationTypes: [
                {
                    type: 'Editors',
                    description:
                        'The invitee will be able to modify the contents of the specified instance and start applications there, but will not be able to invite other people or create new instances.',
                    value: 'instanceEditors'
                },
                {
                    type: 'Viewers',
                    description:
                        'The invitee will be able to read or distribute the contents of the instance to other spaces, but will not be able to start an application or invite people/create new instances.',
                    value: 'instanceViewers'
                }
            ]
        }
    },
    computed: {
        ...mapState('spaceStore', ['spaceInstances', 'spaceUsers', 'fetchingInstances']),
        ...mapGetters('spaceStore', ['currentSpaceType']),
        instanceNames() {
            let instanceNameList = []
            if (this.spaceInstances && this.currentSpaceType === this.spaceTypes.RESEARCH_SPACE) {
                const filteredInstances = this.spaceInstances.filter(instance => instance.long_id !== this.instanceFixedNames.DISTRIBUTED_INSTANCE)
                const sortedInstances = sortArray(filteredInstances, 'long_id', 'ascending', true)
                instanceNameList = sortedInstances.map(instance => instance.long_id)
            } else if (this.spaceInstances) {
                const sortedInstances = sortArray(this.spaceInstances, 'long_id', 'ascending', true)
                instanceNameList = sortedInstances.map(instance => instance.long_id)
            }
            return instanceNameList
        },
        validationErrorType() {
            if (this.foundInvalidEmails) {
                return this.emailValidationErrorTypes.INVALID_EMAILS_ERROR
            } else if (this.backendError || this.emailsValidationError) {
                return this.emailValidationErrorTypes.EMAIL_VALIDATION_BACKEND_ERROR
            }
            return null
        },
        selectedInstanceId() {
            if (this.selectedInstance && this.spaceInstances) {
                const selectedInstance = this.spaceInstances.filter(instance => instance.long_id === this.selectedInstance)
                return selectedInstance[0].iid
            }
            return null
        },
        showInvitationErrorsDialog() {
            if (this.foundInvalidEmails || this.backendError || this.emailsValidationError) {
                return true
            }
            return false
        },
        emails: function () {
            if (this.validatedEmails.length) {
                return this.validatedEmails
            } else if (this.emailsData.length) {
                return this.emailsData
            }
            return []
        }
    },
    mounted() {
        if (this.$props.dataForInstanceToInviteTo) {
            this.selectedInstance = this.$props.dataForInstanceToInviteTo.instanceName
        } else {
            this.selectedInstance = this.instanceFixedNames.MASTER_INSTANCE
        }
    },
    watch: {
        dataForInstanceToInviteTo: function (nextVal, preVal) {
            if (nextVal && nextVal.instanceName) {
                this.selectedInstance = nextVal.instanceName
            }
        }
    },
    methods: {
        updateEmailString: function () {
            this.validatedEmails = []
            const emails = splitEmails(this.emailsInput)
            this.emailsData = emails
            this.emailList = emails.map(email => email.email)
        },
        setInstanceNameToTheNewInstance: function (instanceName) {
            this.selectedInstance = instanceName
        },
        inviteUsers: function (confirmed) {
            if (confirmed) {
                this.error = false
                let apiURL
                if (this.invitationType === this.tabValues.INSTANCE_EDITOR_TAB) {
                    apiURL = '/invitations/invite_instance_editor/' + this.selectedInstanceId
                } else if (this.invitationType === this.tabValues.INSTANCE_VIEWER_TAB) {
                    apiURL = '/invitations/invite_instance_viewer/' + this.selectedInstanceId
                }
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'Inviting users...',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'info'
                })
                this.inviting = true
                this.numInvitationsToSend = this.emailList.length
                this.emailList.forEach(email => {
                    const postBody = { requestee_email: email, generate_link_only: this.generateInvitationLinksOnly }
                    this.$axios
                        .post(apiURL, postBody, { timeout: 300000 })
                        .then(response => {})
                        .catch(() => {
                            this.failedInvitations.push(email)
                            this.error = true
                        })
                        .finally(() => {
                            this.numInvitationsToSend -= 1
                            if (this.numInvitationsToSend === 0) {
                                this.inviting = false
                                this.emailsInput = ''
                                this.$store.dispatch('spaceStore/fetchSpaceInvitations', this.$route.params.sid)
                                this.$store.dispatch('showSnackBar', {
                                    snackBarText: 'Invitations have been successfully sent. Invited users will receive an email to accept the invitation.',
                                    snackBarTimeout: 5000,
                                    snackBarIcon: 'check_circle'
                                })
                            }
                        })
                })
            }
        },
        validateEmailsAndInviteUsers: function (confirmed) {
            if (confirmed) {
                this.validateEmails(this.emailList).finally(() => {
                    if (!this.emailsValidationError && !this.backendError && !this.foundInvalidEmails) {
                        this.inviteUsers(true)
                    }
                })
            }
        }
    }
}
</script>
